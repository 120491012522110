import axios, { AxiosInstance } from 'axios';
import i18n from '../i18n';

const headers = {
  'Content-type': 'application/json',
};

const options = {
  baseURL: process.env.VUE_APP_API_URL,
  headers,
};

const apiClient: AxiosInstance = axios.create(options);

apiClient.interceptors.request.use((config) => {
  const { token } = (window as any).AI_WIDGET_SETTINGS;
  if (token && config.headers) config.headers.Authorization = `Token ${token}`;
  config.headers.lang = i18n.global.locale.value;
  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export default apiClient;
