import axios from '@/plugins/axios';
import { TService } from '@/types/TService';

interface IPayload {
  action: TService;
  image?: string;
  images?: string[];
  params: any,
}

export const generateImage = async ({ action, image, params, images }: IPayload): Promise<any> => (
  new Promise((resolve, reject) => {
    const data = {
      sourceType: 'base64',
      action,
      image,
      images,
      params,
    };
    axios.post('widget/v1', data).then((result) => {
      resolve(result?.data?.data);
    }).catch((e) => {
      reject(e?.response?.data);
    });
  })
);
