import { TPosition } from '@/types/shadow/TPosition';

export const state = {
  loading: false,
  imageForActions: '',
  imageResult: '',
  imagePrev: '',
  sourceBgImage: '',
  shadowParams: {
    shadowColor: '#ffffff',
    paddingIndex: -1,
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    position: 'center' as TPosition,
    id: '',
    isShadow: true,
  },
  shadowParamsFormat: 1,
  shadowParamsType: 0,
  shadowParamsCanvasHeight: 1000,
  shadowParamsCanvasWidth: 1000,
  resultImg: '',
  resultId: '',
  imgName: '',
  generationLoading: false,
  sourceShadowImg: '',
};
